import { useConfig } from '../../hooks'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, FormControl, Select, MenuItem, OutlinedInput } from '@material-ui/core'
import { Image } from 'cloudinary-react'
import ReactCountryFlag from 'react-country-flag'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		//padding: '16px',
		textAlign: 'center',
	},
	left: {},
	middle: {},
	right: {
		textAlign: 'right',
	},
	headerText: {
		fontWeight: 400,
		minWidth: 0,
		fontSize: 10,
		textAlign: 'center',
		color: theme.palette.primary.main === '#ffffff' ? theme.palette.secondary.main : '#ffffff',
	},
	icon: {
		fill: theme.palette.primary.main === '#ffffff' ? theme.palette.secondary.main : '#ffffff',
	},
}))

const useOutlinedInputStyles = makeStyles(theme => ({
	root: {
		'& $notchedOutline': {
			borderColor:
				theme.palette.primary.main === '#ffffff' ? theme.palette.secondary.main : '#ffffff',
		},
		'&:hover $notchedOutline': {
			borderColor:
				theme.palette.primary.main === '#ffffff' ? theme.palette.secondary.main : '#ffffff',
		},
		'&$focused $notchedOutline': {
			borderColor:
				theme.palette.primary.main === '#ffffff' ? theme.palette.secondary.main : '#ffffff',
		},
	},
	focused: {},
	notchedOutline: {},
}))

const LocaleMenu = ({ locale, changeLocale, LOCALES }) => {
	const classes = useStyles()
	const { config } = useConfig()
	const outlinedInputClasses = useOutlinedInputStyles()
	// { value: LOCALES.SPANISH, label: 'Spanish', flag: 'ES', text: 'ES' },
	const options = [
		{ value: LOCALES.ENGLISH, label: 'English', flag: 'GB', text: 'ENG' },
		{ value: LOCALES.FRENCH, label: 'French', flag: 'FR', text: 'FR' },
		{ value: LOCALES.GERMAN, label: 'German', flag: 'DE', text: 'DE' },
		{ value: LOCALES.DUTCH, label: 'Dutch', flag: 'NL', text: 'NL' },
		...(config.rallyType === 'DKC'
			? [{ value: LOCALES.ARABIC, label: 'Arabic', flag: 'SA', text: 'عرب' }]
			: []),
	]

	return (
		<FormControl variant="outlined" size="small">
			<Select
				labelId="locale-label"
				id="locale-select"
				value={locale}
				onChange={e => {
					e.preventDefault()
					changeLocale(e.target.value)
				}}
				input={
					<OutlinedInput
						//labelWidth={labelWidth}
						name="locale"
						id="outlined-locale-simple"
						classes={outlinedInputClasses}
					/>
				}
				inputProps={{
					classes: {
						icon: classes.icon,
					},
				}}
			>
				{options.map(option => (
					<MenuItem key={option.flag} value={option.value}>
						<ReactCountryFlag
							countryCode={option.flag}
							svg
							style={{
								width: '1em',
								height: '1em',
							}}
						/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

const AppHeader = ({ locale, changeLocale, LOCALES }) => {
	const classes = useStyles()
	const { config } = useConfig()
	const { rallyCode, rallyName } = config
	const logoPath = 'races/' + rallyCode + '.png'

	return (
		<Grid container spacing={2} className={classes.root}>
			<Grid item xs={9} container>
				<Grid item xs={3} className={classes.left} />
				<Grid item xs={8} md={8} className={classes.middle}>
					{' '}
					<Image publicId={logoPath} width="90" height="40" />{' '}
					{config.isRallyNameHeader && (
						<Typography noWrap className={classes.headerText}>
							{rallyName}
						</Typography>
					)}
				</Grid>
				<Grid item xs={1} className={classes.right} />
			</Grid>
			<Grid item xs={3}>
				<LocaleMenu LOCALES={LOCALES} locale={locale} changeLocale={changeLocale} />
			</Grid>
		</Grid>
	)
}

export default AppHeader
