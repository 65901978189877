import { makeStyles } from '@material-ui/core/styles'
import { useConfig } from '../../hooks'
import { MenuListRaces, MenuListOV } from './'

const useStyles = makeStyles(theme => ({
	root: {
		position: 'static',
	},
	ovMenu: {
		padding: 2,
		marginTop: 5,
		width: '100%',
		overflowX: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		scrollbarWidth: 'none',
	},
	ovButton: {
		borderRadius: '1em',
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		padding: 5,
		whiteSpace: 'nowrap',

		//height: '48px'
		//border-radius: 24px;
		//padding: 25
	},
}))

function MenuOV({
	rankingSelectedOV,
	setRankingSelectedOV,
	rankingSelectedRace,
	setRankingSelectedRace,
	setStagesRT,
	setStagesCHS,
	setRankingNum,
	setRankingDay,
	setRankingLeg,
	setRankingAMPM,
	setRankingJokers,
	setRankingJokersSpecials,
	rankingDay,
	msgDay,
	//onlineStatus
}) {
	const classes = useStyles()
	const { config } = useConfig()
	const { isMultiRaces, days } = config

	return (
		<>
			<div className={classes.root}>
				{isMultiRaces && days > 1 && (
					<MenuListRaces
						rankingSelectedRace={rankingSelectedRace}
						setRankingSelectedRace={setRankingSelectedRace}
						setRankingSelectedOV={setRankingSelectedOV}
						classes={classes}
						rankingType={'OV'}
					/>
				)}
				<MenuListOV
					rankingSelectedOV={rankingSelectedOV}
					setRankingSelectedOV={setRankingSelectedOV}
					rankingSelectedRace={rankingSelectedRace}
					setStagesRT={setStagesRT}
					setStagesCHS={setStagesCHS}
					setRankingNum={setRankingNum}
					setRankingDay={setRankingDay}
					setRankingLeg={setRankingLeg}
					setRankingAMPM={setRankingAMPM}
					setRankingJokers={setRankingJokers}
					setRankingJokersSpecials={setRankingJokersSpecials}
					rankingDay={rankingDay}
					msgDay={msgDay}
					classes={classes}
				/>
			</div>
		</>
	)
}

export default MenuOV
