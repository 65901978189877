import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
//import { useIntl } from 'react-intl'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { useConfig, useStateWithLocalStorage } from '../../hooks'
import * as appStorage from '../../utils/appStorage'

const OVS_LIST_QUERY = gql`
	{
		ovsList {
			id
			name
			num
			dayNum
			legNum
			ampm
			isOnline
			isJokers
			isJokersSpecials
			race {
				id
				name
			}
			rts {
				id
				name
				nameText
			}
			chs {
				id
				name
			}
		}
	}
`

function MenuListOV({
	rankingSelectedOV,
	setRankingSelectedOV,
	rankingSelectedRace,
	setStagesRT,
	setStagesCHS,
	setRankingNum,
	setRankingDay,
	setRankingLeg,
	setRankingAMPM,
	setRankingJokers,
	setRankingJokersSpecials,
	isData,
	classes,
	msgDay,
}) {
	const { config } = useConfig()
	const { isMultiRaces, days } = config
	//const intl = useIntl()
	const [ovMenuRef, setOvMenuRef] = useState(null)
	const [scrollLeftValue, setScrollLeftValue] = useStateWithLocalStorage('scrollLeftOV', 0)
	const [ovsList, setOvsList] = useState([])

	const {
		loading: ovsListLoading,
		error: ovsListError,
		data: ovsListData,
	} = useQuery(OVS_LIST_QUERY, {
		pollInterval: config.pollInterval || 60000,
	})
	const handleChange = value => {
		const scrollValue = ovMenuRef.scrollLeft
		setScrollLeftValue(scrollValue)
		setRankingSelectedOV(value)
	}

	useEffect(() => {
		if (ovMenuRef) {
			ovMenuRef.scrollLeft = scrollLeftValue
		}
	}, [ovMenuRef, scrollLeftValue])

	useEffect(
		() => {
			if (ovsListData) {
				const filteredList = ovsListData.ovsList.filter(ov => ov.id === rankingSelectedOV)
				const stagesRT = filteredList.length ? filteredList[0].rts : []
				const stagesCHS = filteredList.length ? filteredList[0].chs : []
				const rankingNum = filteredList.length ? filteredList[0].num : null
				const rankingDay = filteredList.length ? filteredList[0].dayNum : null
				const rankingLeg = filteredList.length ? filteredList[0].legNum : null
				const rankingAMPM = filteredList.length ? filteredList[0].ampm : null
				const rankingJokers = filteredList.length ? filteredList[0].isJokers : null
				const rankingJokersSpecials = filteredList.length ? filteredList[0].isJokersSpecials : null
				setStagesRT(stagesRT)
				setStagesCHS(stagesCHS)
				setRankingNum(rankingNum)
				setRankingDay(rankingDay)
				setRankingLeg(rankingLeg)
				setRankingAMPM(rankingAMPM)
				setRankingJokers(rankingJokers)
				setRankingJokersSpecials(rankingJokersSpecials)
			}
		},
		[
			ovsListData,
			setStagesRT,
			setStagesCHS,
			setRankingNum,
			setRankingDay,
			setRankingLeg,
			setRankingAMPM,
			setRankingJokers,
			setRankingJokersSpecials,
			rankingSelectedOV,
		] //NOTE: add ovsList to dependencies cause infinite render (needs investigate)
	)

	useEffect(() => {
		const rankingSelectedRaceLS = appStorage.get('selectedRace')
		if (
			ovsList.length &&
			rankingSelectedRace === rankingSelectedRaceLS &&
			(rankingSelectedOV === null || !ovsList.some(ov => ov.id === rankingSelectedOV))
		) {
			if (ovsList[0].isOnline) setRankingSelectedOV(ovsList[0].id)
		}
	}, [rankingSelectedOV, setRankingSelectedOV, ovsList, rankingSelectedRace])

	useEffect(() => {
		if (ovsListData) {
			const data =
				isMultiRaces && days > 1
					? ovsListData.ovsList.filter(rt => rt.race.id === rankingSelectedRace)
					: ovsListData.ovsList
			setOvsList(data)
		}
	}, [rankingSelectedRace, setOvsList, ovsListData, isMultiRaces, days])

	if (ovsListLoading) return 'Loading...'
	if (ovsListError) return `Error! ${ovsListError && ovsListError.message}`

	return (
		<>
			<ToggleButtonGroup
				variant="outlined"
				size="small"
				exclusive={true}
				onChange={(event, value) => {
					if (value || value === 0) {
						handleChange(value)
					}
				}}
				value={rankingSelectedOV}
				className={classes.ovMenu}
				ref={ref => setOvMenuRef(ref)}
			>
				{ovsList.map(ov => {
					const ovID = ov.id
					//TODO: check this
					/*const ovName = isMultiRaces
						? `${intl.formatMessage({ id: msgDay })} ${ov.dayNum}`
						: ov.name.replace(msgDay.toUpperCase(), intl.formatMessage({ id: msgDay }))*/
					const ovName = ov.name
					return (
						<ToggleButton
							key={ovID}
							value={ovID}
							disabled={!ov.isOnline}
							className={`${classes.ovButton}`}
						>
							{ovName}
						</ToggleButton>
					)
				})}
			</ToggleButtonGroup>
		</>
	)
}

export default MenuListOV
