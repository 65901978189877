import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { FormattedMessage, useIntl } from 'react-intl'
import { format, subMinutes } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { MenuDEP } from '../components/SubMenus'
import { MUITable } from '../components/MUITable'
import { useConfig } from '../hooks'

const useStyles = makeStyles(theme => ({
	root: {
		position: 'static',
	},
}))

const DEPARTURES_DAY_QUERY = gql`
	query departuresDayQuery($id: ID!) {
		depDay(id: $id) {
			id
			leg {
				name
				num
				dayNum
			}
			times {
				id
				position
				team {
					id
					raceNumber
					teamName
					cars {
						model
						averages {
							day
							avg
						}
						legStart
						legEnd
						groupDisplay
						classDisplay
					}
					race {
						name
						text
					}
					isRiders
				}
				timeAM
				timePM
				timeStringAM
				timeStringPM
				intervalRB
			}
			startTimeAM
			startTimePM
		}
	}
`

function Departures(props) {
	const classes = useStyles()
	const intl = useIntl()
	const { config } = useConfig()
	const { isRoadbook, roadbookInterval, isDeparturePM, rallyType, nameCar } = config
	const [depDay, setDepDay] = useState(null)
	const [times, setTimes] = useState([])
	//	const [dayNum, setDayNum] = useState(null)
	const [legNum, setLegNum] = useState(null)
	const [startTimeAM, setStartTimeAM] = useState(null)
	const [startTimePM, setStartTimePM] = useState(null)

	const {
		loading: depDayLoading,
		error: depDayError,
		data: depDayData,
	} = useQuery(DEPARTURES_DAY_QUERY, {
		skip: !depDay,
		variables: { id: depDay },
	})

	useEffect(() => {
		if (!depDayLoading && depDayData) {
			setTimes(depDayData.depDay.times)
			//setDayNum(depDayData.depDay.leg.dayNum)
			setLegNum(depDayData.depDay.leg.num)
			setStartTimeAM(depDayData.depDay.startTimeAM)
			setStartTimePM(depDayData.depDay.startTimePM)
		}
	}, [depDayLoading, depDayData])
	if (depDayLoading) return 'Loading...'
	if (depDayError) return `Error! ${depDayError && depDayError.message}`

	const columnsDepartures = [
		{
			Header: <FormattedMessage id="position" />,
			accessor: 'position',
			maxWidth: 30,
		},
		{ Header: '#', accessor: 'team.raceNumber', id: 'raceNumber', maxWidth: 30 },
		{
			Header: 'Roadbook',
			id: 'roadbook',
			accessor: row => {
				const rowIntervalRB = row.intervalRB ? row.intervalRB : roadbookInterval
				const startTime = row.timeAM ? row.timeAM : row.timePM
				const time = isRoadbook
					? format(subMinutes(new Date(startTime), rowIntervalRB), 'HH:mm:ss')
					: null
				return time
			},
			maxWidth: 80,
		},
		{
			Header: () =>
				rallyType === 'DKC' ? (
					<FormattedMessage id="timeAM" values={{ am: 'Bivouac' }} />
				) : isDeparturePM ? (
					<FormattedMessage id="timeAM" values={{ am: 'AM' }} />
				) : (
					'Start'
				),
			accessor: 'timeStringAM',
			maxWidth: 60,
			Cell: row => <strong>{row.value}</strong>,
		},
	]

	if (isDeparturePM || !startTimeAM) {
		columnsDepartures.push({
			Header: () =>
				rallyType === 'DKC' ? (
					<FormattedMessage id="timePM" values={{ pm: intl.formatMessage({ id: 'firstRZ' }) }} />
				) : (
					<FormattedMessage id="timePM" values={{ pm: 'PM' }} />
				),
			accessor: 'timeStringPM',
			maxWidth: 60,
		})
	}

	columnsDepartures.push(
		{
			Header: <FormattedMessage id="crew" />,
			accessor: 'team.teamName',
			Cell: ({ cell }) => {
				return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
			},
			minWidth: 120,
			maxWidth: 200,
		},
		{
			Header:
				nameCar === 'Vehicle' ? <FormattedMessage id="vehicle" /> : <FormattedMessage id="car" />,
			id: 'car',
			accessor: row =>
				row.team &&
				row.team.cars.filter(car => car.legStart <= legNum && car.legEnd >= legNum)[0].model,
			minWidth: 100,
			maxWidth: 120,
		}
	)

	if ((config.isMultiRaces || config.isRiders) && rallyType !== 'TAO') {
		columnsDepartures.push({
			Header: <FormattedMessage id="race" />,
			accessor: row => (row.team?.isRiders ? config.ridersName : row.team?.race?.text),
			maxWidth: 80,
			id: 'race',
		})
	}
	/*
	if (config.isDisplayAVG === true && config.rallyType !== 'TAO') {
		columnsDepartures.push({
			Header: <FormattedMessage id="avg" />,
			id: 'avg',
			accessor: row =>
				row.team.cars
					.filter(car => car.legStart <= legNum && car.legEnd >= legNum)[0]
					.averages.filter(avg => avg.day === dayNum)[0].avg,
			maxWidth: 60,
		})
	}

	if (config.isDisplayGroup === true && config.rallyType !== 'TAO') {
		columnsDepartures.push({
			Header: <FormattedMessage id="group" />,
			id: 'group',
			accessor: row =>
				row.team.cars.filter(car => car.legStart <= legNum && car.legEnd >= legNum)[0].groupDisplay,
			maxWidth: 60,
		})
	}
  */
	const hiddenColumns = []
	if (!isRoadbook) hiddenColumns.push('roadbook')
	if (!startTimeAM) hiddenColumns.push('timeStringAM')
	if (!startTimePM) hiddenColumns.push('timeStringPM')

	return (
		<>
			<div className={classes.root}>
				<MenuDEP depDay={depDay} setDepDay={setDepDay} />
				{times && times.length > 0 && (
					<MUITable
						columns={columnsDepartures}
						data={times}
						type="departures"
						hiddenColumns={hiddenColumns}
					/>
				)}
			</div>
		</>
	)
}

export default Departures
