import { Link as RouterLink } from '@reach/router'
import { FormattedMessage, useIntl } from 'react-intl'
import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Link,
	TextField,
	Tooltip,
} from '@material-ui/core'
import {
	Timer,
	TimerTwoTone,
	DriveEta,
	EmojiEvents,
	Settings,
	Schedule,
	PictureAsPdf,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useConfig, useOptions } from '../../hooks'

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 15,
		overflow: 'hidden',
		//border: '1px solid blue'
	},
	icon: {
		color: theme.palette.secondary.main,
	},
	text: {
		color: theme.palette.secondary.main,
	},
	carNumber: {
		color: theme.palette.secondary.main,
	},
}))

const makeItemsList = (
	rallyType,
	rtPrefix,
	isDisplayRT,
	isDisplayOV,
	isDisplayPDF,
	isDisplayDepartures,
	isCHS,
	nameCHS
) => {
	const itemsList = [
		{
			primaryText: <FormattedMessage id={`rankings${rtPrefix}`} />,
			path: '/stages',
			icon: <Timer />,
			display: isDisplayRT ? true : false,
		},
		{
			primaryText: `${nameCHS}`,
			path: '/chs',
			icon: <TimerTwoTone />,
			display: isCHS ? true : false,
		},
		{
			primaryText: <FormattedMessage id="rankingsOV" />,
			path: '/overall',
			icon: <EmojiEvents />,
			display: isDisplayOV ? true : false,
		},
		{
			primaryText: <FormattedMessage id="departures" />,
			path: '/departures',
			icon: <Schedule />,
			display: isDisplayDepartures ? true : false,
		},
		{
			primaryText: <FormattedMessage id="startList" />,
			path: '/startlist',
			icon: <DriveEta />,
			display: true,
		},
		{
			primaryText: 'PDF',
			path: '/pdf',
			icon: <PictureAsPdf />,
			display: isDisplayPDF ? true : false,
		},
		{
			primaryText: 'Settings',
			path: '/settings',
			icon: <Settings />,
			display: true,
		},
	]
	return itemsList
}

const NavContent = ({ location, sideBar, setOpen, collapsed }) => {
	const classes = useStyles()
	const intl = useIntl()
	const { carNumber, setCarNumber } = useOptions()
	const { config } = useConfig()
	const {
		rallyType,
		rtPrefix,
		isDisplayRT,
		isDisplayOV,
		isDisplayPDF,
		isDisplayDepartures,
		isCHS,
		nameCHS,
	} = config
	const pathname =
		location.pathname === '/'
			? isDisplayRT === true
				? '/stages'
				: '/startlist'
			: location.pathname
	location.pathname = pathname
	const itemsList = makeItemsList(
		rallyType,
		rtPrefix,
		isDisplayRT,
		isDisplayOV,
		isDisplayPDF,
		isDisplayDepartures,
		isCHS,
		nameCHS
	)

	const onBlur = () => {
		setOpen('primarySidebar', false)
	}

	const onKeyDown = e => {
		if (e.key === 'Enter') {
			e.preventDefault()
			e.target.blur()
		}
	}

	const handleChange = event => {
		const value = event.target.value
		if (Number(value) || value === '') {
			const inputValue = value === '' ? value : Number(value)
			setCarNumber(inputValue)
		}
	}

	return (
		<div className={classes.root}>
			<List>
				{itemsList.map(
					({ primaryText, icon, path, display }, i) =>
						display && (
							<Link
								key={i}
								component={RouterLink}
								to={path}
								color="secondary"
								onClick={() => setOpen('primarySidebar', false)}
							>
								<ListItem key={i} selected={pathname === path} button>
									<Tooltip title={primaryText}>
										<ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
									</Tooltip>
									<ListItemText
										primary={primaryText}
										primaryTypographyProps={{ noWrap: true }}
										className={classes.text}
									/>
								</ListItem>
							</Link>
						)
				)}
				{
					<Tooltip title={intl.formatMessage({ id: 'TTcarNumber' })}>
						<TextField
							id="input-car"
							type="tel"
							label="Car#"
							value={carNumber}
							variant="outlined"
							size="small"
							margin="normal"
							autoComplete="off"
							onChange={e => handleChange(e)}
							onBlur={onBlur}
							onKeyDown={onKeyDown}
							color="primary"
						/>
					</Tooltip>
				}
			</List>
		</div>
	)
}

export default NavContent
